import React from 'react';
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import './Projects.css';
import 'react-tooltip/dist/react-tooltip.css';


import github from '../Assets/github.svg';
import discord from '../Assets/discord.svg';
import browse from '../Assets/browse.svg';


const Projects = () => {
const navigate = useNavigate();
const handleNerdcakeClick = () => {

  window.scrollTo(0, 0);

  navigate("/nerdcake");
};

  return (
    <div className="projects-main">
      <motion.section
        id="projects"
        className="projects-section"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0 }}
        transition={{ duration: 0.8 }}
      >

<h1 className="projectsheader2">Some Projects</h1>


<div className="project-flex">
  
        {/* Project 1 - Food Fest Game */}
        <div className="project-card card">
        <motion.div
            className="project1"
            initial={{ opacity: 0, y: 50 }} 
            whileInView={{ opacity: 1, y: 0 }} 
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.8 }}
          >
            
            <div className='projectstext'>
            <h1 className='projectheader'>Food Fest Game</h1>
            <div className="project-tags">
  <motion.span
    className="label fun"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0 }}
  >
    Fun
  </motion.span>

  <motion.span
    className="label engaging"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.2 }}
  >
    Engaging
  </motion.span>

  <motion.span
    className="label compatible"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.4 }}
  >
    Compatible
  </motion.span>

  <motion.span
    className="label interactive"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.3 }}
  >
    Interactive
  </motion.span>
</div>

              A fun and interactive React-based game developed for a university food festival. The game lets players compete by scoring points in a Doodle Jump-style game. Firebase backend ensures real-time scoring and leaderboards.

            </div>
            <div className="button-container">
            <button className="githubbtn githubbtn1" onClick={() => window.open('https://github.com/nisalherath/foodfestgame', '_blank')}>
              <img src={github} className="githubimg" alt="GitHub" />
              Source Code
            </button>
            <button className="githubbtn githubbtn1" onClick={() => window.open('https://foodfestgame.netlify.app/', '_blank')}>
              <img src={browse} className="githubimg" alt="GitHub" />
              Play The Game
            </button>
            </div>
        </motion.div>
        </div>


       <div className="project-card card">
        {/* Project 2 - Nerdcake Discord Bot */}
        <motion.div
            className="project2"
            initial={{ opacity: 0, y: 50 }} 
            whileInView={{ opacity: 1, y: 0 }} 
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.8 }}
          >

            <div className='projectstext'>
            <h1 className='projectheader'>Nerdcake Bot</h1>
            <div className="project-tags">
  <motion.span
    className="label versatile"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0 }}
  >
    Versatile
  </motion.span>

  <motion.span
    className="label useful"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.2 }}
  >
    Useful
  </motion.span>

  <motion.span
    className="label fast"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.4 }}
  >
    Fast
  </motion.span>

  <motion.span
    className="label messages"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.3 }}
  >
    Messages
  </motion.span>

  <motion.span
    className="label reliable"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.8 }}
  >
    Reliable
  </motion.span>

  <motion.span
    className="label tag24-7"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 1 }}
  >
    24-7 / 365
  </motion.span>
</div>

            A multipurpose Discord bot developed with Python. It automates tasks like fetching random images, providing game deals, delivering positive quotes, and running speed tests to enhance user experience in Discord servers.
</div>
<div className="button-container">
            <button className="githubbtn githubbtn1" onClick={() => window.open('https://discord.com/oauth2/authorize?client_id=1288790078826479646', '_blank')}>
              <img src={discord} className="githubimg" alt="GitHub" />
              Try it on Discord
            </button>
            <button className="githubbtn githubbtn1" onClick={handleNerdcakeClick}>
              <img src={browse} className="githubimg" alt="GitHub" />
              Read More
            </button>
            </div>
        </motion.div>
        </div>



      <div className="project-card card">
        {/* Project 3 - Car Rental App */}
        <motion.div
            className="project3"
            initial={{ opacity: 0, y: 50 }} 
            whileInView={{ opacity: 1, y: 0 }} 
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.8 }}
          >

            <div className='projectstext'>
            <h1 className='projectheader'>Car Rental App</h1>
            <div className="project-tags">
  <motion.span
    className="label minimalui"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0 }}
  >
    Minimal UI
  </motion.span>

  <motion.span
    className="label userfriendly"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.2 }}
  >
    User-friendly
  </motion.span>

  <motion.span
    className="label efficient"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.4 }}
  >
    Efficient
  </motion.span>

  <motion.span
    className="label multitask"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.3 }}
  >
    Multi-Task
  </motion.span>

  <motion.span
    className="label reliable"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.8 }}
  >
    Reliable
  </motion.span>
</div>

      A full-fledged React-based rental management application with a Java Springboot backend. It allows users to easily view available vehicles, rent them quickly, and efficiently manage their rental periods. </div>
            <div className="button-container">
            <button className="githubbtn githubbtn1" onClick={() => window.open('https://github.com/nisalherath/RentalApp.git', '_blank')}>
              <img src={github} className="githubimg" alt="GitHub" />
              Source Code
            </button>
            <button className="githubbtn githubbtn1" onClick={() => window.open('https://rental101.netlify.app/', '_blank')}>
              <img src={browse} className="githubimg" alt="GitHub" />
              Try the App
            </button>
            </div>
        </motion.div>

        </div>




      <div className="project-card card">
        {/* Project 4 - Social Media Bot */}
        <motion.div
            className="project4"
            initial={{ opacity: 0, y: 50 }} 
            whileInView={{ opacity: 1, y: 0 }} 
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.8 }}
          >

            <div className='projectstext'>
            <h1 className='projectheader'>Social Media Bot</h1>
            <div className="project-tags">
  <motion.span
    className="label versatile"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0 }}
  >
    Versatile
  </motion.span>

  <motion.span
    className="label useful"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.2 }}
  >
    Useful
  </motion.span>

  <motion.span
    className="label fast"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.4 }}
  >
    Fast
  </motion.span>

  <motion.span
    className="label messages"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.3 }}
  >
    Messages
  </motion.span>

  <motion.span
    className="label reliable"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 0.8 }}
  >
    Reliable
  </motion.span>

  <motion.span
    className="label tag24-7"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.1 }}
    variants={{
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    }}
    transition={{ duration: 0.3, delay: 1 }}
  >
    24-7 / 365
  </motion.span>
</div>

            A Python-based bot that automates social media posts by generating a random Sinhala word, turning it into an image, and posting it to Facebook, Instagram, and Twitter every hour.
            </div>
            <div className="button-container">
            <button className="githubbtn githubbtn1" onClick={() => window.open('https://web.facebook.com/RandomSinhalaWord', '_blank')}>
              <img src={browse} className="githubimg" alt="GitHub" />
              On Facebook
            </button>
            <button className="githubbtn githubbtn1" onClick={() => window.open('https://www.instagram.com/sinhala333/', '_blank')}>
              <img src={browse} className="githubimg" alt="GitHub" />
              On Instagram
            </button>
            <button className="githubbtn githubbtn1" onClick={() => window.open('https://x.com/sinhala333', '_blank')}>
              <img src={browse} className="githubimg" alt="GitHub" />
              On Twitter
            </button>
            </div>
        </motion.div>
        </div>
        </div>
      </motion.section>
    </div>
  );
};

export default Projects;
